import React from "react"

import Header from "../templates/header";
import Footer from "../templates/footer";

export default function Layout({ children }) {
  return (
    <div>
    <Header/>
      {children}
    <Footer/>
    </div>
  )
}